<template>
  <v-div v-if="loading">
    <Loader></Loader>
  </v-div>

  <header-component></header-component>
  <Notification></Notification>
  <router-view></router-view>
  <footer-component></footer-component>
</template>

<script>
import FooterComponent from "../components/footer/FooterComponent.vue";
import HeaderComponent from "../components/header/HeaderComponent.vue";
import Loader from "../components/Loader/index.vue";
import { storeToRefs } from "pinia";
import { loaderStore, useCommonStore } from "../stores";
import Notification from "../components/alert/Notification.vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import configuration from "../config.json";
import { useMetaStore } from '../stores/metastore.store';

import { onMounted } from "vue";
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Loader,
    Notification,
  },
  mounted() {
    const marketplaceContent = document.querySelector('meta[name="marketplace_type"]').getAttribute('content');
    const marketplaceName = document.querySelector('meta[name="marketplace_name"]').getAttribute('content');
    const marketplaceLogo = document.querySelector('meta[name="marketplace_logo"]').getAttribute('content');
    // this.updatemarketplaceContent(marketplaceContent);
    const metaStore = useMetaStore();
    metaStore.setmarketplaceContent(marketplaceContent);
    metaStore.setmarketplaceName(marketplaceName);
    metaStore.setmarketplaceLogo(marketplaceLogo);
    
    document.onreadystatechange = () => { 
      if (document.readyState == "complete") { 
        const { global_tracker_id } = configuration.gtag;
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-10873823582";
        script.async = true;
        document.head.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", global_tracker_id);
      } 
    }
  },
  methods: {
    updatemarketplaceContent(content) {
      let metaTag = document.querySelector('meta[name="marketplace_type"]');
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'marketplace_type');
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    }
  },
  setup() {
    const { loading } = storeToRefs(loaderStore());
    const commonStore = useCommonStore();

    return {
      loading,
    };
  },
};
</script>
<style>
@font-face {
  font-family: "Lato";
  src: local("Lato"), url(../fonts/Lato-Regular.ttf) format("truetype");
}
</style>
