<template>
  <v-container class="registration-container">
    <v-card variant="none">
      <div class="section-title d-flex justify-center">
        <h3 class="py-7 mx-19">Buyer Registration Form</h3>
      </div>
      <form @submit.prevent="submit" class="registration" autocomplete="garbage">
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6">
            <v-text-label class="font-weight-bold">Name*</v-text-label>
            <v-text-field
              variant="outlined"
              v-model="name.value.value"
              :error-messages="name.errorMessage.value"
              label="Name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="6" md="6">
            <v-text-label class="font-weight-bold">Email ID {{ emailMandat ? "*" : '' }}</v-text-label>
            <v-text-field
              type="email"
              autocomplete="off"
              variant="outlined"
              v-model="email.value.value"
              @input="sanitizeEmail"
              :rules="emailRules"
              :error-messages="email.errorMessage.value"
              label="Ex: xyz123@gmail.com"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6">
            <v-text-label class="font-weight-bold">Mobile Number*</v-text-label>
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="6" xs="6">
                <v-text-field
                  variant="outlined"
                  v-model="phone.value.value"
                  type="number"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.value.slice(0,10);"
                  :disabled="otpSent"
                  min=0
                  max=9999999999
                  :error-messages="phone.errorMessage.value"
                  label="10 digit mobile number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" xs="6" class="mt-3 otp-msg">
                <v-btn
                  v-if="!otpSent && !otpVerified"
                  color="blue"
                  variant="outlined"
                  class="mt-1"
                  @click="sendEmailOtp"
                  size="large"
                  :disabled="(!phone.value?.value || (phone.value?.value?.length < 10)) || countDown > 0"
                >
                  {{ otpVerified ? "Verified" : "SEND OTP" }}
                </v-btn>
                <template v-if="otpSent && !otpVerified">
                  <p class="green">OTP sent!
                    <v-icon
                      size="small"
                      icon="mdi mdi-check"
                      color="#14ae5c"
                    ></v-icon>
                  </p>
                  <button class="resend-btn" :disabled="countDown > 0" @click="sendOtp">Resend</button>
                </template>
                <template v-else-if="otpVerified">
                  <p class="green verified-txt">Verified
                    <v-icon
                      size="small"
                      icon="mdi mdi-check"
                      color="#14ae5c"
                    ></v-icon>
                  </p>
                </template>
              </v-col>
            </v-row>
            <v-row v-if="otpSent && !otpVerified" class="pb-5">
              <v-col
                cols="12"
                md="12"
                lg="12"
                sm="12"
                xs="12"
                class="otp-container"
              >
                <div class="otp-wrapper">
                  <v-otp-input
                    ref="otpInput"
                    v-model:value="bindModal"
                    input-classes="otp-input"
                    separator=""
                    :num-inputs="6"
                    :should-auto-focus="true"
                    input-type="letter-numeric"
                    :conditionalClass="['one', 'two', 'three', 'four']"
                    @on-change="handleOnChange"
                  />
                  <p v-if="countDown > 0" class="text-center mt-5">
                    00 : {{ addLeadingZero(countDown) }}
                  </p>
                </div>
                <v-btn
                  color="blue"
                  variant="outlined"
                  size="large"
                  :disabled="!otpFilled"
                  @click="submitOTP"
                >
                  Verify
                </v-btn>
              </v-col>

            </v-row>
          </v-col>

          <v-col cols="12" xs="12" sm="6" md="6">
            <v-text-label class="font-weight-bold">Password*</v-text-label>
            <v-text-field
              v-model="password.value.value"
              :error-messages="password.errorMessage.value"
              :type="passwordVisible ? 'text' : 'password'"
              label="Minimum 8 characters"
              single-line="true"
              autocomplete="new-password"
              variant="outlined"
              :append-inner-icon="
                passwordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append-inner="passwordVisibility"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6">
            <v-text-label class="font-weight-bold">Organisation Name</v-text-label>
            <v-text-field
              variant="outlined"
              single-line="true"
              v-model="organisationName.value.value"
              :error-messages="organisationName.errorMessage.value"
              label="Enter your company name here"
            ></v-text-field>
          </v-col>
        </v-row>


        <!-- <v-row>
          <v-col cols="12" xs="12" sm="6" md="6">
            <v-text-label class="font-weight-bold"
              >Select MarketPlaces</v-text-label
            >
            <v-select
              variant="outlined"
              v-model="selectedItems"
              :items="items"
              item-value="value"
              item-title="value"
              :error-messages="select.errorMessage.value"
              label="Enter your company name here"
              multiple
              chips
              clearable
            ></v-select>
          </v-col>

          <v-col></v-col>
        </v-row> -->

        <p class="font-weight-bold">
          <input type="checkbox" id="checkbox" v-model="tnc" @change="handleChange"/>&nbsp;
          <label for="checkbox">Accept Terms and Condition <a href="#" @click="handleTnc">click here to view</a></label>
        </p>

        <v-dialog
          :key="item"
          v-model="itemData.dialog"
          width="800"
          content-class="terms-modal"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5"> Terms & conditions</span>
              <v-btn
                icon
                variant="text"
                @click="itemData.dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-div class="tnc-container"> <TermsOfPurchase /> </v-div>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn variant="outlined" @click="handleAccept(false)">
                Reject
              </v-btn>
              <v-btn
                class="bg-blue"
                variant="text"
                @click="handleAccept(true)"
              >
                Accept
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="d-flex text-center align-center justify-center">
          <v-btn class="me-4 bg-blue" type="submit" size="large">
            Submit Form
          </v-btn>
        </div>
      </form>
    </v-card>
  </v-container>
</template>

<script>
import app from "./index.js";

export default app;
</script>
