const LoginPage = defineAsyncComponent({
  loader: () =>import("../login/LoginPage.vue"),
})

const ChangePassword = defineAsyncComponent({
  loader: () =>import("../ForgotPassword/ChangePassword.vue")
})

const ProfileDetails = defineAsyncComponent({
  loader: () =>import("../Profile/Details.vue")
})

const CreatePassword = defineAsyncComponent({
  loader: () =>import("../CreatePassword/index.vue")
})

const ForgotPasswordPage = defineAsyncComponent({
  loader: () =>import("../ForgotPassword/index.vue")
})

const LoginOTPPage = defineAsyncComponent({
  loader: () =>import("../login/OtpLogin.vue")
})

const OTPPage = defineAsyncComponent({
  loader: () =>import("../ForgotPassword/ConfirmOTP.vue")
})

const GoogleTranslator = defineAsyncComponent({
  loader: () =>import("./GoogleTranslator.vue"),
})

import logoImage from "@/../assets/images/logo.png";
import { useAuth, usePaginationStore, uselotProducts } from "../../stores";
import { isMobile } from "../../misc/index";
import { computed, onMounted, reactive } from "vue";
import { storeToRefs } from 'pinia';
import configuration from "../../config.json";
import { defineAsyncComponent } from 'vue';
import { useMetaStore } from '../../stores/metastore.store';

export default {
  setup() {
    const lotStore = uselotProducts();
    const authStore = useAuth();
    const metaStore = useMetaStore();
    const marketplaceContent = computed(() => metaStore.marketplaceContent);
    const marketplaceName = computed(() => metaStore.marketplaceName);
    const marketplaceLogo = computed(() => metaStore.marketplaceLogo);

    const user = computed({
      get: () => authStore.user,
    });

    const profileVisible = computed({
      get: () => authStore.profileDialog,
    });

    const headerState = reactive({
      drawer: false,
    });

    const handleChangePassword = () => {
      authStore.setChangePasswordDialog(true);
    };

    const showProfile = () => {
      headerState.drawer = !headerState.drawer
      authStore.setProfileDialog(true);
    };

    const userSignin = () => {
      headerState.drawer = false;
      if (user?.value?.authentication_token) {
        authStore.signOut("");
      } else {
        authStore.setLoginDialog(true);
      }
    };

    const addedCarts = computed({
      get: () => lotStore.carts,
    });

    onMounted(() => {
      if(user?.value?.authentication_token && configuration.Lots.MOQ.enabled ) {
        lotStore.fetchCarts()
      }
    })

    return { configuration, whatsAppUrl: configuration.whatsAppURL, carts: addedCarts, logoImage, marketplaceLogo, user, showProfile, headerState, marketplaceContent, marketplaceName, userSignin, handleChangePassword, profileVisible };
  },

  components: {
    LoginPage,
    GoogleTranslator,
    ForgotPasswordPage,
    ChangePassword,
    CreatePassword,
    LoginOTPPage,
    OTPPage,
    ProfileDetails,
  },
  methods: {
    redirectToProducts() {
      const pageStore = usePaginationStore();
      const metaStore = useMetaStore();
      const marketplaceContent = metaStore.marketplaceContent;
      const marketplaceName = metaStore.marketplaceName;
      pageStore.setPage(1);
        const productsRoute =  marketplaceContent === 'private' ? `/${marketplaceName}/products` : '/products' ;
        this.$router.push({ path: productsRoute });
      // window.location.href = '/products'
      this._.setupState.headerState.drawer = !this._.setupState.headerState.drawer
    },
    handleCartRedirect(id) {
      this.$router.push(`/cart/${id}`);
    },
    handleRedirect(componentName) {
      const metaStore = useMetaStore();
      const marketplaceContent = metaStore.marketplaceContent;
      const marketplaceName = metaStore.marketplaceName;
      const componentRoute =  marketplaceContent === 'private' ? `/${marketplaceName}/${componentName}` : `/${componentName}` ;
      if(marketplaceName === 'private'){
        this.$router.push({ path: componentRoute });
      }else {
        this.$router.push({ name: componentName });
      }
      this._.setupState.headerState.drawer = !this._.setupState.headerState.drawer
    },
    isMobile: isMobile,
  },
};