<template>
  <v-app class="my-cart__container mt-5">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="title">
          <p>
            Cart - {{cartDetails?.organization?.marketplace_name}} <small>({{ lots.length }} items)</small>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <template v-if="lots.length >= 1">
          <v-col xs="12" sm="12" md="9" lg="9" xl="9" class="items-section">
            <v-row v-for="lot in lots" :key="lot.id" :class="cartClasses(lot)">
              <v-col md="10" sm="12" class="lot-left-content">
                <div class="remove" @click="removeCartLot(lot.id)">
                  <v-icon icon="mdi-close"></v-icon>
                </div>
                <div
                  class="lot-image"
                  @click="redirectToLot(lot.lot_publish.id)"
                >
                  <img :src="lot.image_url || lotImage" eager contain />
                </div>
                <div class="lot-details">
                  <span v-if="cartClasses(lot)" class="error-message">{{lotError(lot)}}</span>
                  <div
                    class="details-title"
                    @click="redirectToLot(lot.lot_publish.id)"
                  >
                    <p>
                      {{ lot.lot_publish.lot_name }}
                    </p>
                    <p>
                      Price per lot
                      <strong>₹ {{ lot.priceSlot?.price }}</strong> Qty
                      <strong>{{
                        lot.lot_publish.current_available_quantity
                      }}</strong>
                    </p>
                  </div>
                  <v-div class="lot-options">
                    <v-icon
                      color="#0053AD"
                      v-if="lot.qty - 1 >= 0"
                      icon="mdi-minus-circle-outline"
                      @click="updateLotQty(lot.id, false)"
                    ></v-icon>
                    <fieldset class="bid-filter">
                      <legend>No of Lots</legend>
                      <v-selector
                        :disabled="!!cartClasses(lot)"
                        @update:modelValue="updateLotQty(lot.id, false, $event)"
                        :options="lot.lotItems"
                        :clearable="false"
                        :model-value="lot.quantity"
                      ></v-selector>
                    </fieldset>
                    <v-icon
                      v-if="
                        lot.qty + 1 <= lot?.lot_publish?.maximum_lots_per_buyer
                      "
                      color="#0053AD"
                      background="#E1F1FF"
                      icon="mdi-plus-circle-outline"
                      @click="updateLotQty(lot.id, true)"
                    ></v-icon>
                  </v-div>
                </div>
              </v-col>
              <v-col md="2" sm="12" class="amount">
                <p>₹ {{ formatCurrency(lot.buyPrice) }}</p>
                <p>
                  <span
                    >₹
                    {{
                      formatCurrency(lot.lot_publish.mrp * lot.quantity)
                    }}</span
                  >&nbsp;
                  <span class="discount"><b>({{ lot.discountPercentage }}% off)</b></span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            xs="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
            class="payment-section"
          >
            <v-card>
              <v-card-body>
                <v-col col="12" class="title">
                  <p>Order Details</p>
                </v-col>
                <v-col col="12" class="payment-desc" sm="12" md="12" lg="12">
                  <p>
                    Lots Price
                    <b>₹ {{ formatCurrency(orderDetails.lotsPrice) }}</b>
                  </p>
                  <p>
                    Lots Discount
                    <b
                      class="discount"
                      > - ₹
                      {{
                        formatCurrency(
                          orderDetails.lotsPrice - orderDetails.discountedPrice
                        )
                      }}</b
                    >
                  </p>
                  <p>
                    Total Discount
                    <b
                      >₹
                      {{
                        formatCurrency(
                          orderDetails.lotsPrice - orderDetails.discountedPrice
                        )
                      }}</b
                    >
                  </p>
                  <p>
                    Total Amount
                    <b>₹ {{ formatCurrency(orderDetails.discountedPrice) }}</b>
                  </p>
                </v-col>
                <v-col col="12" class="item-desc" sm="12" md="12" lg="12">
                  <v-btn
                    :disabled="shortOnBuy || enableBuy(lots)"
                    color="blue"
                    class="bg-blue"
                    size="large"
                    width="100%"
                    @click="handleShowBuySummary"
                  >
                    Proceed to Buy
                  </v-btn>
                  <p class="lot-error" v-if="shortOnBuy">
                    Minimum order value should be ₹ {{ formatCurrency(cartDetails.mov_value) }}
                  </p>
                </v-col>
              </v-card-body>
            </v-card>
          </v-col>
        </template>
        <template v-else>
          <v-col
            xs="12"
            sm="12"
            md="9"
            lg="9"
            xl="9"
            class="items-section"
          >
            <v-row class="empty-cart">
              <v-col md="12" sm="12">
                <img :src="emptyCart" eager contain />
              </v-col>
              <v-col md="12" sm="12">
                <v-btn
                  variant="outlined"
                  class="add-btn"
                  color="#1962D5"
                  @click="redirectToMarketplaces()"
                >
                  Add Lots
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
      <v-row v-if="lots.length > 0">
        <v-btn
          variant="outlined"
          class="add-btn"
          color="#1962D5"
          @click="redirectToLots()"
        >
          + Add Another Lot
        </v-btn>
      </v-row>
    </v-container>

    <CartBuyDialog
      :dialog="cartDetailState.dialog"
      :orderAmount="orderDetails.discountedPrice"
      :closeLotDialog="closeLotDialog"
      :handleShowBuySummary="handleShowBuySummary"
      :param_bid_id="queryParams.id"
      :cartNumber="cartDetails.cart_number"
      :lotQty="lotQty"
    ></CartBuyDialog>
  </v-app>
</template>

<script>
import app from "./index.js";

export default app;
</script>
