<template>
  <div class="hero">
    <v-container class="hero">
      <v-row no-gutters>
        <v-col lg="12">
          <v-row>
            <v-col cols="12" lg="7" sm="12" align-self="center" class="order-lg-1 order-2">
              <div class="pt-10">
                <p class="sub-heading">Online auction For Liquidation, Returns and Overstock</p>
                <h1 class="my-8 heading">
                  Millions of products for your inventory needs
                </h1>
                <v-row no-gutters class="sub-heading">
                  <v-col lg="6" cols="12">
                    <p class="d-flex align-items-center justify-start mb-2">
                      <v-img
                        :src="groupImg"
                        width="22"
                        height="22"
                        contain
                        eager
                        class="flex-0-1 me-2"
                      ></v-img>
                      Top retail B2B marketplaces
                    </p>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <p class="d-flex align-items-center justify-start mb-2">
                      <v-img
                        :src="groupImg"
                        width="22"
                        height="22"
                        eager
                        contain
                        class="flex-0-1 me-2"
                      ></v-img>
                      Thousands of liquidation auctions.
                    </p>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <p class="d-flex align-items-center justify-start mb-2">
                      <v-img
                        :src="groupImg"
                        width="22"
                        height="22"
                        contain
                        class="flex-0-1 me-2"
                      ></v-img>
                      Hundred of product categories.
                    </p>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <p class="d-flex align-items-center justify-start mb-2">
                      <v-img
                        :src="groupImg"
                        width="22"
                        height="22"
                        contain
                        class="flex-0-1 me-2"
                      ></v-img>
                      All lot sizes and conditions.
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-5 shop-action-btns">
                  <v-col cols="12" sm='5' lg="5">
                    <v-btn @click="handleRedirect('/products')"
                     class="auction-btn" >SHOP ALL AUCTIONS</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm='7' lg="7" class="marketplace-link">
                    <router-link to="/marketplace"
                     class="browse-btn" >Browse Marketplaces</router-link
                    >
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col lg="5" class="order-lg-2 order-1">
              <v-img
                :src="homeimg3"
                cover
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <section class="search-auctions">
    <v-container class="mt-5">
      <div class="text-center py-12">
        <h3 class="heading">
          Search auctions via the largest network of B2B liquidation
          marketplaces
        </h3>
      </div>
      <v-row no-gutters>
        <v-col cols="12" lg="4" sm="12">
          <div class="search-auctions-box">
            <v-img
              :src="nomiddleImg"
              height="50"
            ></v-img>
            <p class="mt-4 mb-6">No Middleman</p>
          </div>
        </v-col>
        <v-col cols="12" lg="4" sm="12">
          <div class="search-auctions-box">
            <v-img
              :src="sizesImg"
              height="50"
            ></v-img>
            <p class="mt-4 mb-6">All Lot Sizes</p>
          </div>
        </v-col>
        <v-col cols="12" lg="4" sm="12">
          <div class="search-auctions-box">
            <v-img
              :src="altImg"
              height="50"
            ></v-img>
            <p class="mt-4 mb-6">Attractive Prices</p>
          </div>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="text-center my-5">
          <a>
            <v-btn
              v-if="!user.id"
              variant="flat"
              color="#1962d5"
              style="color: #fff"
              size="large"
              @click="handleRedirect('/signUp')"
            >
              REGISTER AS A BUYER
            </v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="services marketplace service-marketplace">
    <v-container>
      <v-row>
        <v-col lg="12" sm="12" md="12">
          <div class="text-center py-8">
            <h1 class="heading">Featured Marketplaces</h1>
          </div>
        </v-col>

        <v-row justify="center" v-if="!loading">
          <v-col
            v-for="(service, index) in services"
            :key="index"
            cols="12"
            md="3"
            lg="3"
            xl="3"
            sm="6"
            xs="12"
            class="marketplace-card"
          >
            <v-card class="mx-auto ma-3 pa-0" :elevation="13">
              <v-card-title class="pa-0">
                <div class="top-label">
                  <div class="ribbon">
                    <div class="ribbon-content font-weight-bold">
                      <span
                        class="active-number text-caption text-sm-body-2 text-md-body-1 text-lg-body-1"
                      >
                        <strong>{{ service.lot_count }}&nbsp;</strong>
                      </span>
                      <span class="active-lot">Active Lots</span>
                    </div>
                  </div>
                  <div class="new">
                    <p class="text-sm-body-2 text-md-body-1 text-lg-body-1">
                      New!
                    </p>
                  </div>
                </div>
              </v-card-title>

              <v-card-body>
                <div class="icon-box-content">
                  <v-img
                    :src="service.image"
                    :lazy-src="service.image"
                    height="175px"
                    contain
                    class="card-img"
                    @click="handleRedirect(service.link + `?BiddingMode=${service.bidType}`)"
                    style="cursor: pointer"
                  ></v-img>
                  <div class="liquid-label">
                    <span class="liquid-text">Liquidation</span>
                  </div>
                </div>
              </v-card-body>

              <v-card-actions v-if="!user.id">
                <v-btn
                  color="blue-darken-1 flex-grow-1"
                  variant="flat"
                  @click="handleRedirect('/signUp')"
                >
                  Register
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-col lg="12" sm="12" md="12" v-else>
            <v-skeleton-loader
            class="mx-auto border"
            type="image, article"
            ></v-skeleton-loader>
        </v-col>

        <v-col lg="12" sm="12" md="12"  class="text-right">
          <router-link to="/marketplace" class="link">Browse All Marketplaces</router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="trusted-thousands">
    <v-container>
      <v-row>
        <v-col xl="6" md="12" sm="12">
          <div class="py-8">
            <h1 class="heading text-center">
              Trusted by Thousands of small business owners
            </h1>
          </div>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col xl="10" md="12" sm="12" cols="12">
          <div class="featured-section">
            <div>
              <v-carousel
                class=""
                hide-delimiters
                :show-arrows="false"
                cycle
                style="height: 400px;"
              >
                <v-carousel-item
                  v-for="person in testimonials"
                  :key="person.name"
                >
                  <v-row class="d-none d-md-flex">
                    <div class="carousel-v_col ma-2 pa-0" v-for="n in 3" :key="n">
                      <v-card
                        color="#f7f7f7"
                        :elevation="8"
                        class="px-6 pt-6 pb-5 ma-2 rounded-xl h-100"
                      >
                        <div>
                          <div class="d-flex flex-column">
                            <v-scale-transition>
                              <div>
                                <p class="owner-desc">
                                  {{ personData(n, "desc") }}
                                </p>
                                <v-divider
                                  :thickness="3"
                                  class="border-opacity-100 my-5"
                                  color="info"
                                ></v-divider>
                                <div class="d-flex person-desc">
                                  <div>
                                    <v-img
                                      :src="personData(n, 'profileImg')"
                                      width="84"
                                    ></v-img>
                                  </div>
                                  <div class="px-5 text-center">
                                    <h4 class="owner-name">
                                      {{ personData(n, "name") }}
                                    </h4>
                                    <p class="owner-location font-weight-bold">
                                      {{ personData(n, "location") }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </v-scale-transition>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row class="d-flex d-md-none">
                    <div class="carousel-v_col ma-2 pa-0">
                      <v-card
                        color="#f7f7f7"
                        :elevation="8"
                        class="pa-6 ma-2 rounded-xl"
                      >
                        <div>
                          <div class="d-flex flex-column">
                            <v-scale-transition>
                              <div>
                                <p class="owner-desc">
                                  {{ person.desc }}
                                </p>
                                <v-divider
                                  :thickness="3"
                                  class="border-opacity-100 my-5"
                                  color="info"
                                ></v-divider>
                                <div class="d-flex person-desc">
                                  <div>
                                    <v-img
                                      :src="person.profileImg"
                                      width="84"
                                    ></v-img>
                                  </div>
                                  <div class="px-5 text-center">
                                    <h4 class="owner-name">
                                      {{ person.name }}
                                    </h4>
                                    <p class="owner-location font-weight-bold">
                                      {{ person.location }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </v-scale-transition>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <KeyPartners />
  <Featured isLoggedin="!!user.id" />
  <template v-if="!user.id">
    <RegisterBanner />
  </template>
</template>

<script>
  import app from './index.js';

  export default app;
</script>
