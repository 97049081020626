<template>
  <v-app class="my-order__container mt-5">
    <v-container fluid v-if="shouldShowContent">
        <v-div class="bid-cards__node d-none d-sm-flex" v-if="marketplaceContent !== 'private' || marketplaceContent === 'all'">
          <v-col cols="12" class="filter-section px-0">
            <v-div class="align-center title">
                My Orders
            </v-div>
            <v-div class="filter-type">
              <fieldset class="bid-filter">
                <legend>Bidding Mode</legend>
                <v-selector @update:modelValue="handleUpdate" v-model="type" :options="bidOptions" :clearable="false"></v-selector>
              </fieldset>
            </v-div>
          </v-col>
        </v-div>
        <v-row n-gutters class="d-flex d-sm-none"  v-if="marketplaceContent !== 'private' || marketplaceContent === 'all'">
            <v-col cols="12" class="text-center">
                <v-div class="align-center title">
                    My Orders
                </v-div>
            </v-col>
            <v-col cols="12">
                <v-div class="filter-type">
                <fieldset class="bid-filter">
                    <legend>Bidding Mode</legend>
                    <v-selector @update:modelValue="handleUpdate" v-model="type" :options="bidOptions" :clearable="false"></v-selector>
                </fieldset>
                </v-div>
            </v-col>
        </v-row>

        <template v-if="orders.length < 1">
            <v-col cols="12" class="d-flex justify-center">
             No Data Available.
            </v-col>
        </template>
        <v-sheet v-for="(order) in orders" :key="order.id" border rounded class="pa-2 order-card mb-5">
            <v-row @click="() => redirectToDetails(order)" class="cursor-pointer">
                <v-col lg="3" md="12" sm="12" cols="12">
                    <v-img 
                        class="bg-white mx-auto" width="100%" 
                        :aspect-ratio="1"
                        v-bind:src="orderImg(order.order_items?.[0])"
                        eager
                        cover>
                    </v-img>
                </v-col>
                <v-col lg="9" sm="12">
                    <div class="order-details">
                        <v-row>
                            <v-col lg="10">
                                <h1 class="order-title">{{getValue(order, "name")}}</h1>
                            </v-col>
                            <v-col lg="2" class="text-right">
                                <v-icon class="recycle-icon"  v-if="order.waste_lot == true" color="white">mdi-recycle</v-icon>
                            </v-col>
                        </v-row>
                        
                        <div>
                            <v-row class="ma-0 desc">
                                <v-col lg="5" class="pa-1 info">
                                    <div class="mt-4">
                                        <span>Order ID:</span>
                                        <span class="ml-4">
                                            {{getValue(order, 'order_number')}}
                                        </span>
                                    </div>
                                    <div>
                                        <span>Order Placed:</span>
                                        <span class="ml-4">
                                            {{orderTime(getValue(order, 'created_at'))}}
                                        </span>
                                    </div>
                                    <div class="mb-4" v-if="type === 'MOQ'">
                                        <span>No of Lots:</span>
                                        <span class="ml-4">
                                            {{getValue(order, 'quantity')}}
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="d-lg-none d-md-none d-flex">
                                <v-col cols="7" class="pa-2">
                                    <div class="mb-5">
                                        <b>Total Amount</b>
                                        <span>
                                            <span class="mx-2">:</span>{{order.currencySymbol}}{{formatCurrency(getValue(order, 'total_payable_amount'))}}
                                        </span>
                                    </div>
                                    <div class="mb-5">
                                        <b>Paid Amount</b>
                                        <span>
                                            <span class="mx-2">:</span>{{order.currencySymbol}}{{formatCurrency(getValue(order, 'paid_amount'))}}
                                        </span>
                                    </div>
                                    <div class="mb-5">
                                        <b>Pending Amount</b>
                                        <span>
                                            <span class="mx-2">:</span>{{order.currencySymbol}}{{formatCurrency(getValue(order,'payable_amount'))}}
                                        </span>
                                    </div>
                                    <div class="mb-5">
                                        <b>Payment Status</b>
                                        <span>
                                            <span class="mx-2">:</span>{{order.order_status}}
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="5" class="pa-2">
                                    <div class="mobile-stepper amount-desc">
                                        <div :class="getClasses(order.order_status, 'step')">
                                            <v-icon size="large" icon="mdi-check-circle" color="green" class="mr-2"></v-icon>
                                                <span :class="getClasses(order.order_status)">Ordered</span>
                                        </div>
                                        <div :class="getClasses(order.order_status, 'step')">
                                            <v-icon size="large" :icon="paymentIcon(order.order_status)" :color="statusColor(order.order_status)" class="mr-2"></v-icon>
                                                <span :class="getClasses(order.order_status)"> Payment</span>
                                        </div>
                                        <div :class="getClasses(order.order_status, 'step')">
                                            <v-icon size="large" :icon="shippIcon(order.order_status)" :color="statusColor(order.order_status)" class="mr-2"></v-icon>
                                                <span :class="getClasses(order.order_status)">Shipped</span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 d-lg-flex d-md-flex d-none">
                                <v-col lg="8" class="pa-0">
                                    <ul class="d-lg-flex d-sm-flex stepper my-4">
                                        <li class="d-flex success">
                                            <div class="step-info">
                                            <v-icon size="small" icon="mdi-check-circle" color="green" class="mr-2"></v-icon>
                                            <span>Ordered</span>
                                            </div>
                                        </li>
                                        <li :class="getClasses(order.order_status, 'd-flex')">
                                            <div class="step-info">
                                            <v-icon size="small" :icon="paymentIcon(order.order_status)" :color="statusColor(order.order_status)" class="mr-2"></v-icon>
                                            <span>Payment</span>
                                            </div>
                                        </li>
                                        <li :class="getClasses(order.order_status, 'd-flex')">
                                            <div class="step-info">
                                            <v-icon size="small" :icon="shippIcon(order.order_status)" :color="statusColor(order.order_status)" class="mr-2"></v-icon>
                                            <span>Shipped</span>
                                            </div>
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                            <div class="amount-desc d-lg-flex d-md-flex d-none">
                                <v-row class="ma-0">
                                    <v-col sm="6" lg="5" class="px-1">
                                        <b>Total Amount</b>
                                        <span>
                                        <span class="mx-2">:</span>{{order.currencySymbol}} {{formatCurrency(getValue(order, 'total_payable_amount'))}}
                                        </span>
                                    </v-col>
                                    <v-col sm="6" lg="5" class="px-1">
                                        <b>Pending Amount</b>
                                        <span>
                                        <span class="mx-2">:</span>{{order.currencySymbol}} {{formatCurrency(getValue(order, 'payable_amount'))}}
                                        </span>
                                    </v-col>
                                    <v-col sm="6" lg="5" class="px-1 py-0">
                                        <b>Paid Amount</b>
                                        <span>
                                        <span class="mx-2">:</span>{{order.currencySymbol}} {{formatCurrency(getValue(order, 'paid_amount'))}}
                                        </span>
                                    </v-col>
                                    <v-col sm="6" lg="5" class="px-1 py-0">
                                        <b>Payment Status</b>
                                        <span>
                                        <span class="mx-2">:</span> {{order.order_status}}
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
        <v-col cols="12">
        <v-pagination @update:modelValue="handlePageUpdate" v-model="page" :total-visible="6" :length="getAllPages"></v-pagination>
        </v-col>
    </v-container>
    <v-container v-else>
        <v-row class="d-flex justify-center">
          <div class="error-container mt-16" style="box-shadow: none !important;"> 
            <h3> Sign in with your account details </h3> 
            
            <p class="mt-8">
              Please login and explore some other auctions or contact support team.
            </p> 
            <v-btn variant="outlined" color="#092D66" elevation="0" @click="userSignin">SignIn</v-btn>
          </div> 
        </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import app from './index.js';

  export default app;
</script>