import configuration from "../../config.json";
import lotImage from "@/../assets/images/Box.svg";
import emptyCart from "@/../assets/images/empty-cart.svg";
import { onMounted, onUnmounted, onUpdated, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { fetchCartLots } from "../../Actions/ProductActions";
import { ref } from "vue";
import { reactive } from "vue";
import { useAlertStore, useAuth, uselotProducts } from "../../stores";
import { computed } from "vue";
import { formatCurrency } from "../../misc/formatter";
import CartBuyDialog from "./buyConfirm.vue";

export default {
  setup() {
    const alertStore = useAlertStore();
    const authStore = useAuth();
    const lotStore = uselotProducts();
    const route = useRoute();
    const router = useRouter();
    const lots = ref([]);
    const cartDetails = ref({});
    const queryParams = ref(route.query);
    const user = computed({
      get: () => authStore.user,
    });

    const lotPublishIds = computed({
      get: () => lots.value.map(lot => lot.lot_publish_id)
    });

    const lotQty = computed({
      get: () => lots.value.map(lot => lot.quantity).reduce((partialSum, a) => partialSum + a, 0)
    });

    const orderDetails = computed({
      get: () => {
        return {
          lotsPrice: lots.value.map(a => a.lot_publish.mrp * a.quantity).reduce((partialSum, a) => partialSum + a, 0),
          discountedPrice: lots.value.map(a => ((a.priceSlot?.price ?? 0)) * a.quantity).reduce((partialSum, a) => partialSum + a, 0)
        }
      }
    });

    const shortOnBuy = computed({
      get: () => orderDetails.value.discountedPrice < cartDetails.value.mov_value
    });

    const getLots = async (id) => {
      const resp = await fetchCartLots(id);
      if (resp.status === 200 && resp?.data?.cart_lots) {
        const lotsData = resp.data.cart_lots.map((rec) => {
          const priceSlot = rec.lot_publish.moq_lot_pricings.find(a => a.from_quantity <= rec.quantity && a.to_quantity >= rec.quantity);
          const buyPrice = rec.quantity * (priceSlot?.price ?? 0);
          const discountPercentage = (100 - Math.round((buyPrice / (rec.quantity * rec.lot_publish.mrp)) * 100));

          return {
            ...rec,
            buyPrice,
            discountPercentage,
            priceSlot,
            qty: rec.quantity,
            lotItems: Array(
              rec?.lot_publish.current_available_quantity <= rec?.lot_publish.maximum_lots_per_buyer
                ? Math.abs(rec.lot_publish.current_available_quantity)
                : rec.lot_publish.maximum_lots_per_buyer
            )
              .fill(1)
              .map((a, b) => a + b),
            image_url: rec?.lot_publish.lot_image_urls?.[0] ?? "",
          };
        });

        lots.value = lotsData;
        cartDetails.value = resp.data.cart;
      }
    };

    onUnmounted(() => {
      cartDetailState.dialog = {
        type: "",
        popUp: true
      }
    })

    onMounted(() => {
      if(configuration.Lots.MOQ.enabled){
        lotStore.fetchCarts();
      }
      if (!user?.value?.authentication_token) {
        authStore.setLoginDialog(true);
      } else {
        getLots(route.params.id);
      }

      if(!configuration.Lots.MOQ.enabled) {
        router.push(`/products`);
      }
    });

    const cartDetailState = reactive({
        dialog: {
          type: "",
          popUp: false,
        }
    });

    const handleShowBuySummary = async () => {
      if (user.value?.authentication_token) {
        const errored = await lotStore.getCartBuyDialogSummary(cartDetails.value.cart_number);
        if(!errored) {
          cartDetailState.dialog = {
            type: "buy_summary",
            popUp: true,
            width: 900,
            lotType: 'moq',
            qty: 1,
            price: orderDetails.value.discountedPrice
          };
        } else {
          cartDetailState.dialog = {
            type: "",
            popUp: false,
          };
        }
      } else {
        authStore.setLoginDialog(true);
      }
    };

    const closeLotDialog = (path) => {
      cartDetailState.dialog = {
        type: "",
        popUp: false,
      };
      if(configuration.Lots.MOQ.enabled){
        lotStore.fetchCarts();
      }
      getLots(route.params.id);

      if (path) {
        router.push({ name: path, query: { query: 'MOQ'}});
      }
    };

    const removeCartLot = async (id) => {
      const resp = await lotStore.removeLot({cart_lot_id: id});
      if(resp.status === 200) {
        alertStore.setAlertMessage("success", 'Lot has been removed successfully.', true);
        if(configuration.Lots.MOQ.enabled){
          lotStore.fetchCarts();
        }
        getLots(route.params.id);
      }
    }

    const redirectToLots = () => {
      router.push(cartDetails.value.organization.card_url + `?BiddingMode=moq`)
    }

    const redirectToMarketplaces = () => {
      router.push('/marketplace')
    }

    const redirectToLot = (id) => {
      router.push(`/product_detail/${id}`);
    }

    const updateLotQty = async (id, increment = false, qtyVal) => {
      let qty;
      if(qtyVal) {
        qty = qtyVal;
      } else {
        qty = lots.value.find(a => a.id === id).quantity ?? 0;
        qty = increment ? (qty + 1) : (qty - 1);
      }

      const resp = await lotStore.updateLotQty({quantity: qty, cart_lot_id: id});
      if(resp.status === 200) {
        if(configuration.Lots.MOQ.enabled){
          lotStore.fetchCarts();
        }
        alertStore.setAlertMessage("success", 'Lot quantity has been updated successfully.', true);
        getLots(route.params.id);
      }
    }

    watch(lotStore, async (newValue, oldValue) => {  
      if (newValue.error.id === "bid_price_error") {
        cartDetailState.dialog = {
          type: "",
          popUp: false,
        };
      }
        
      if (newValue.success.id === "checkout_submit") {
        cartDetailState.dialog = {
          type: "buySuccessfully",
          popUp: true,
          width: 900,
        };
      }
    });

    onUpdated(() => {
      if(configuration.Lots.MOQ.enabled){
        lotStore.fetchCarts();
      }
      if (!user?.value?.authentication_token) {
        authStore.setLoginDialog(true);
      } else {
        getLots(route.params.id);
      }
    })
    
    return {
      lotImage,
      emptyCart,
      lots,
      orderDetails,
      formatCurrency,
      redirectToLots,
      cartDetails,
      shortOnBuy,
      updateLotQty, 
      queryParams,
      redirectToLot,
      redirectToMarketplaces,
      removeCartLot,
      closeLotDialog,
      lotQty,
      cartDetailState,
      handleShowBuySummary,
      lotPublishIds
    };
  },
  components: {
    CartBuyDialog
  },
  methods: {
    cartClasses(lot) {
      return ((lot.lot_publish.bid_remaining_time > 0) && (lot.quantity <= lot?.lot_publish?.current_available_quantity)) ? '' : 'errored'
    },
    enableBuy(lots) {
      let enabled = true;
      lots.map(lot => {
        if((lot.lot_publish.bid_remaining_time < 1) || ((lot.quantity > lot?.lot_publish?.current_available_quantity))) {
          enabled = false;
        }
      })

      return !enabled;
    },
    lotError(lot) {
      let err;
      if(lot.lot_publish.bid_remaining_time < 1) {
        err = "Out of Stock!"
      }

      if(lot.quantity > lot?.lot_publish?.current_available_quantity) {
        err = 'Out of Stock!';
      }

      return err;
    }
  },
  watch: {
    $router: {
      handler: function (path) {
        const pageQuery = path.currentRoute.value.query;
      },
      deep: true,
      immediate: true,
    },
  },
};
