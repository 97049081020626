<template>
  <v-dialog
    v-model="dialog.popUp"
    persistent
    class="lotdetails-dialog"
    :max-width="dialog?.width || 1100"
  >
    <v-card v-if="!loading">
      <v-card class="buy-summary" v-if="dialog.type === 'buy_summary'">
        <v-card-title>Order Summary</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" lg="8" sm="12">
              <div class="pay-amount mt-5 mb-3">
                <p><b>Pay Amount through:</b></p>
                <div class="box">
                  <v-radio-group inline v-model="buybid.paymentType">
                    <v-radio
                      v-for="paymentOption in buyNowSummary?.payment_options"
                      :label="paymentOption.value"
                      :value="paymentOption.id"
                      color="primary"
                      :key="paymentOption.id"
                    ></v-radio>
                    <!-- <v-radio label="NEFT/RTGS/IMPS" value="NEFT/RTGS/IMPS" color="primary"></v-radio> -->
                  </v-radio-group>
                </div>
              </div>
              <v-table density="compact" class="table-border mt-5 order-summary">
                <thead>
                  <tr>
                    <th class="text-left head" colspan="2">Bank Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{{ buyNowSummary?.bank_details?.["Company Name"] ?  buyNowSummary?.bank_details?.["Company Name"] : buyNowSummary?.bank_details?.["Name"]}}</td>
                  </tr>
                  <tr>
                    <td>Beneficiary A/C</td>
                    <td>{{ buyNowSummary?.bank_details?.["Account Number"] }}</td>
                  </tr> 
                  <tr>
                    <td>IFSC Code</td>
                    <td>{{ buyNowSummary?.bank_details?.["IFSC Code"] }}</td>
                  </tr>
                  <tr>
                    <td>Branch Name</td>
                    <td>{{ buyNowSummary?.bank_details?.["Branch Name"] }}</td>
                  </tr>
                  <tr>
                    <td>Account Type</td>
                    <td>{{ buyNowSummary?.bank_details?.["Account Type"] }}</td>
                  </tr>
                  <tr>
                    <td>Bank Name</td>
                    <td>{{ buyNowSummary?.bank_details?.["Bank Name"] }}</td>
                  </tr> 
                </tbody>
              </v-table>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <v-carousel
                hide-delimiter-background
                height="auto"
                hide-delimiters="true"
                continuous
                cycle={true}
                class="buy-carousel"
              >
                <TimerBadge
                  :remainingTime="lotSummary.bidRemainingTime" 
                  :endDate="buyNowSummary?.lot_publish?.end_date || new Date()"
                  class="timer"
                ></TimerBadge>
                <v-carousel-item
                  v-for="(item, i) in image"
                  :key="i"
                  :src="item"
                  cover
                  style="color: s #bdbdbd"
                ></v-carousel-item>
              </v-carousel>
              <v-table density="compact" class="table-border mt-2 order-summary">
                <thead>
                  <tr>
                    <th class="text-left head" colspan="2">Order Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Order Amount</td>
                    <td>
                      &#8377; {{ formatCurrency(buyNowSummary?.total_price) || "N/A" }}
                    </td>
                  </tr>
                  <tr>
                    <td>Discount</td>
                    <td>
                      &#8377; {{ formatCurrency(buyNowSummary?.discount) || "N/A" }}
                    </td>
                  </tr>
                  <tr>
                    <td>Payable Amount</td>
                    <td>
                      &#8377; {{ formatCurrency(buyNowSummary?.buy_price) || "N/A" }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center text-center">
          <v-btn @click="dialog.popUp = false" variant="outlined" color="blue"
            >CANCEL</v-btn
          >
          <v-btn @click="checkoutBuyBid" :disabled="!buybid.paymentType" class="bg-theme-primary">CHECKOUT</v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="buy-success" v-else-if="dialog.type === 'buySuccessfully'">
        <!-- <v-card-title>Buy Now</v-card-title> -->
        <v-card-text class="heading text-center">
          Your order
          <span class="font-weight-bold"
            ><a>{{ order_details?.orderIds }}</a></span
          >
          is successfully placed.
        </v-card-text>
        <p class="text-center heading mb-2">
          Thank you for placing on order. Please Remit to the account details
          below.
        </p>
        <v-row class="mx-auto">
          <v-col>
            <v-table density="compact" class="table-border mt-5 order-summary">
              <thead>
                <tr>
                  <th class="text-left head" colspan="2">Bank Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{{ buyNowSummary?.bank_details?.["Company Name"] || buyNowSummary?.bank_details?.["Name"]}}</td>
                </tr>
                <tr>
                  <td>Beneficiary A/C</td>
                  <td>{{buyNowSummary?.bank_details?.["Account Number"]}}</td>
                </tr>
                <tr>
                  <td>IFSC Code</td>
                  <td>{{ buyNowSummary?.bank_details?.["IFSC Code"] }}</td>
                </tr>
                <tr>
                  <td>Branch Name</td>
                  <td>{{ buyNowSummary?.bank_details?.["Branch Name"] }}</td>
                </tr>
                <tr>
                  <td>Account Type</td>
                  <td>{{ buyNowSummary?.bank_details?.["Account Type"] }}</td>
                </tr>
                <tr>
                  <td>Bank Name</td>
                  <td>{{ buyNowSummary?.bank_details?.["Bank Name"] }}</td>
                </tr>                
              </tbody>
            </v-table>
          </v-col>
        </v-row>

        <v-card-text class="d-none">
          <p>Bank Details</p>
          <p>Bank Name :</p>
          <p>HDFC</p>
          <p>IFSC CODE :</p>
          <p>HSBC0560002</p>
          <p>Account Number :</p>
          <p>BLUBRICH12721657224</p>
          <p>Branch Name :</p>
          <p>GANDHI BAZAAR, BANGALORE</p>
          <P>Account Type :</P>
          <p>CURRENT</p>
          <p>Name :</p>
          <p>GREEN ENABLED IT SOLUTIONS PVT LTD</p>
        </v-card-text>

        <v-card-actions class="justify-center mt-5 mb-5">
          <v-btn @click="closeCongratulationsDialog()" class="bg-theme-primary"
            >Ok</v-btn
          >
          <v-btn @click="closeDialog('Home')" class="bg-theme-primary"
            >Continue Shopping</v-btn
          >
          <v-btn @click="closeDialog('MyOrders')" class="bg-theme-primary"
            >My Orders</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-card>
    <v-card v-else-if="loading">
      <Skeleton />
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, defineProps, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import Skeleton from "../Skeleton/index.vue";
import { uselotProducts, loaderStore, useMetaStore } from "../../stores";
import TimerBadge from "../lots/TimerBadge.vue";
import lotImage from "@/../assets/images/Box.svg";
import {formatCurrency} from "../../misc/formatter";
const lotStore = uselotProducts();
const lotSummary = computed(() => lotStore.lotSummary);
const order_details = computed(() => lotStore.order_details);
const buyNowSummary = computed(() => lotStore.cartBuyNowSummary);
const metaStore = useMetaStore();
const loader = loaderStore();
const { loading } = storeToRefs(loaderStore());


const props = defineProps({
  dialog: Object,
  closeLotDialog: Function,
  handleShowBuySummary: Function,
  param_bid_id: String,
  lotQty: Number,
  cartNumber: Number
});

const buybid = reactive({
  paymentType: "",
});

const onUnmounted = () => {
  buybid.paymentType = "";
}

const checkoutBuyBid = () => {
  let checkoutDetail = {
    "cart_number": props.cartNumber,
    "payment_mode": buybid.paymentType
  };

  if (buybid.paymentType) {
    lotStore.checkoutCart(checkoutDetail, props.param_bid_id);
  }
};

const closeDialog = (route, path = "") => {
  buybid.paymentType = "";
  let finalPath;
  if (metaStore.marketplaceContent === 'private' && route == "Home") {
    finalPath = `/${metaStore.marketplaceName}/products`;
  } else if(metaStore.marketplaceContent === 'private' && route == "MyOrders"){
    finalPath = `/${metaStore.marketplaceName}/my_orders?mode=Hybrid`;
  } else if(metaStore.marketplaceContent !== 'private' && route == "Home"){
    finalPath = `/products`;
  } else if(metaStore.marketplaceContent !== 'private' && route == "MyOrders"){
    finalPath = `/my_orders?mode=Hybrid`;
  }
  router.replace(finalPath);

  // else {
  //   finalPath = `/${path}`;
  // }
  // props.closeLotDialog(metaStore.marketplaceContent === 'private' ? metaStore.marketplaceName/path : path)
}

const closeCongratulationsDialog = () => {
  lotStore.setSuccess();
  buybid.paymentType = "";
  props.closeLotDialog();
};

watch(buyNowSummary, (newValue, oldValue) => {
  if (newValue.payment_options) {
    let payment_id = newValue.payment_options.find((option) =>
      option.value.includes("NEFT")
    )?.id;
  }
});
</script>
