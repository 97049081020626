import { createRouter, createWebHistory } from 'vue-router'
import ProductsComponent from "../components/lots/index.vue";
import MarketPlace from "../components/MarketPlace/index.vue";
import LotDetails from "../components/lots/LotDetails.vue";
import MyBids from "../components/Bids/index.vue";
import MyOrder from "../components/Orders/MyOrder.vue";
import MyCart from "../components/Cart/MyCart.vue";
import SignUp from "../components/signUp/SignUp.vue";
import Buyers from "../components/Buyers/index.vue";
import PrivacyPolicy from "../components/PrivacyPolicy/index.vue";
import Sellers from "../components/Sellers/index.vue";
import FAQ from "../components/Faq/index.vue";
import ContactUs from "../components/ContactUs/index.vue";
import AboutUs from "../components/AboutUs/index.vue";
import Support from "../components/Support/index.vue";
import RegistrationSuccess from "../components/RegisterBanner/success.vue";
import TermsOfPurchase from '../components/TermsOfPurchase/index.vue';
import TermsCondition from '../components/TermsCondition/index.vue';
import ItemCondition from "../static/itemCondition.vue";
import Dashboard from "../components/Dashboard/index.vue";
import { useAuth, useMetaStore } from '../stores';
import { computed } from 'vue';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/:organization_id?/cart/:id',
    name: 'Cart',
    component: MyCart
  },
  {
    path: '/marketplace',
    name: 'Home',
    component: MarketPlace
  },
  {
    path: '/:organization_id?/products',
    name: 'Products',
    component: ProductsComponent
  },
  {
    path: '/:marketplaceName/products',
      name: 'MetaProducts',
      component: ProductsComponent,
  },
  {
    path: "/:organization_id?/product_detail/:lot_name?/:id",
    name: "LotDetails",
    component: LotDetails
  },
  {
    path: "/:organization_id?/my_bids",
    name: "MyBids",
    component: MyBids
  },
  {
    path: "/:organization_id?/my_orders",
    name: "MyOrders",
    component: MyOrder
  },
  {
    path: "/signUp",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: ContactUs
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs
  },
  {
    path: "/:organization_id?/support",
    name: "Support",
    component: Support
  },
  {
    path: "/:organization_id?/registration-success",
    name: "RegistrationSuccess",
    component: RegistrationSuccess
  },
  {
    path: "/buyers",
    name: "Buyers",
    component: Buyers
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/tnc",
    name: "Terms",
    component: TermsOfPurchase
  },
  {
    path: "/terms-n-conditions",
    name: "TermsCondition",
    component: TermsCondition
  },
  {
    path: "/sellers",
    name: "Sellers",
    component: Sellers
  },
  {
    path: "/item_conditions",
    name: "ItemConditions",
    component: ItemCondition
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if(to.path !== from.path) {
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach(
  (to, from, next) => {
    const metaStore = useMetaStore();
    const marketplaceContent = metaStore.marketplaceContent;
    const marketplaceName = metaStore.marketplaceName;
    if (to.path.endsWith('/') && marketplaceContent === 'private') {
      next({
        name: 'MetaProducts',
        params: { marketplaceName },
      });
      return;
    }

    if (to.path === '/signUp') {
      const authStore = useAuth();
      const user = computed({
        get: () => authStore.user,
      });

      if (user?.value?.authentication_token) {
        next(
          {
            path: '/products',
          }
        )
        return
      }
    }

    next()
  }
)

export default router
