<template>
  <div class="text-center marketplace">
    <div class="section-title py-10">
      <h3 class="text-h4 text-md-h2">Leading liquidation marketplaces for all your inventory needs</h3>
    </div>

    <v-card class="mx-auto">
      <v-container style="max-width: 1320px;">
        <v-row justify="center" v-if="!loading">
          <v-col v-for="(service, index) in services" :key="index" cols="12" md="3" lg="3" xl="3" sm="6" xs="12"
            class="marketplace-card">
            <v-card class="mx-auto ma-3 pa-0" :elevation="13">
              <v-card-title class="pa-0">
                <div class="top-label">
                  <div class="ribbon">
                    <div class="ribbon-content font-weight-bold">
                      <span class="active-number text-caption text-sm-body-2 text-md-body-1 text-lg-body-1">
                        <strong>{{ service.lot_count }}&nbsp;</strong>
                      </span>
                      <span class="active-lot">Active Lots</span>
                    </div>
                  </div>
                  <div class="new">
                    <p class="text-sm-body-2 text-md-body-1 text-lg-body-1">New!</p>
                  </div>
                </div>
              </v-card-title>

              <v-card-body>
                <div class="icon-box-content">
                  <img :src="service.image" :lazy-src="service.image" height="175px" contain class="card-img"
                    @click="handleRedirect(service.link, service?.bidType)" style="cursor: pointer;" eager />
                  <div class="liquid-label">
                    <span class="liquid-text">Liquidation</span>
                  </div>
                </div>
              </v-card-body>

              <v-card-actions v-if="!user?.authentication_token">
                <v-btn color="blue-darken-1 flex-grow-1" variant="flat" href="/signUp">
                  Register
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <Skeleton />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import app from './index.js';

  export default app;
</script>